.fixed-action-wrapper { 
  @include flex(flex-end, flex-start, row);  
  position: fixed;
  bottom: 90px;
  z-index: 10;

  @media only screen and (max-width: 480px) {
    right: 20px;
    // transform: translate(0, 0);
  }

  @media only screen and (min-width: 481px) {
    right: calc((50% - 240px) + 20px);
    // right: calc(50% - 240px);
    // transform: translate(-50%, 0);
  }
  
  .fixed-action-inner {
    display: grid;
    grid-auto-rows: 48px;
    gap: 12px;
    width: 48px;
    .icon-btn {
      @include flex;
      border-radius: 50%;
      box-shadow: 44px 48px 18px 0px rgba(0, 0, 0, 0.00), 28px 31px 17px 0px rgba(0, 0, 0, 0.01), 16px 17px 14px 0px rgba(0, 0, 0, 0.05), 7px 8px 10px 0px rgba(0, 0, 0, 0.09), 2px 2px 6px 0px rgba(0, 0, 0, 0.10);

      &.read {
        img {
          @include size(22px, 16.5px);          
        }
        border: 1px solid var(--gray200, #E6E6EB);
        background: #FFF;
      }
      &.write {
        img {
          @include size(17.62px, 20.74px);          
        }
        border: 1px solid var(--purple07);
        background: var(--purple06);
      }
    }
  }
}

.navi-bar {
  width: 100%;
  height: var(--G-nav-height);
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: var(--white);
  border-top: 1px solid var(--gray100);
  z-index: 10;
  &__inner {
    padding: 0 20px;
    height: 100%;
    ul {
      @include flex(space-between, center, row);
      height: 100%;
      li {
        @include flex;
        padding: 0 20px;
        height: 100%;
        cursor: pointer;   
        &.active p {
          color: var(--purple07) !important;
        }
        p {
          @include string(11px, 135%, normal, 700, center, var(--gray600));
          margin-top: 2px;
        }

        @each $icon in home, memoryBox, my, reports {
          &.#{$icon}-on {
            &::before {
              @include same(24px);
              content: "";
              display: block;
              background: url($G-path-icon + 'nav-#{$icon}-on.svg') $G-img-contain;              
            }
          } 
          &.#{$icon}-off {
            &::before {
              @include same(24px);
              content: "";
              display: block;
              background: url($G-path-icon + 'nav-#{$icon}-off.svg') $G-img-contain;              
            }
          } 
        }
      }
    }
  }
}

.default-switch {
  width: 51px;
  height: 31px;
  background-color: var(--gray200);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
  position: relative;
  border-radius: 100px;

  &.on {
    background-color: var(--purple06);
    .toggle {
      transform: translateX(20px);
    }
  }

  .toggle {
    width: 27px;
    height: 27px;
    background: var(--white, #FFF);
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.15), 0px 3px 1px 0px rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    position: absolute;
    left: 2px;
    transition: transform 0.2s ease-out;
  }  
}

.switch {
  width: 51px;
  height: 31px;
  background-color: #f87070;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
  position: relative;
  border-radius: 100px;

  &.on {
    background-color: var(--blue06);
    .toggle {
      transform: translateX(20px);
      p {
        color: var(--blue06);
      }
    }
  }

  .toggle {
    width: 27px;
    height: 27px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    left: 2px;
    transition: transform 0.2s ease-out;
    p {      
      @include string(16px, 24px, normal, 700, left, #f87070);
      transition: color 0.2s ease-out;
    }
  }  
}