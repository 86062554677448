.root-container {
  height: calc((var(--vh, 1vh) * 100));
  max-width: 480px;
  width: 100%;
  margin: 0px auto;
  overflow: auto;
  position: relative;
  .main-content {
    height: calc((var(--vh, 1vh) * 100));
    background-color: var(--white);
    overflow: hidden auto;
    scrollbar-width: none;
    --ms-overflow-style: none;
    position: relative;
    &.main-navi-content { 
      height: calc((var(--vh, 1vh) * 100) - var(--G-nav-height));      
    }
  }
}

.layout-p {
  padding-left: var(--G-default-p);
  padding-right: var(--G-default-p);
}