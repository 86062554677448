.userInfo-bar {
  // @include flex;
  // // margin-bottom: 28px;
  // height: 80px;
  // position: sticky;
  // top: 0;
  // left: 0;
  // z-index: 10;
  // background-color: var(--gray100);
  // &__inner {
  //   @include flex(space-between, center, row);
  //   // height: 40px;
  //   width: calc(100% - 40px);

  //   .logo {
  //     height: 24px;
  //     object-fit: contain;
  //   }
  //   .profile {
  //     @include same(40px);
  //     object-fit: contain;
  //     background-color: #D9D9D9;
  //     border-radius: 50%;
  //   }
  // }
  @include flex(space-between, center, row);
  height: 40px;
  margin-bottom: 28px;

  .logo {
    height: 24px;
    object-fit: contain;
  }
  .profile {
    @include same(40px);
    object-fit: contain;
    background-color: #D9D9D9;
    border-radius: 50%;
  }
}

.loading-container {
  @include flex;
  height: 100%;
  .MuiCircularProgress-root {
    color: var(--purple07);
  }
}

.close-title-bar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: var(--white);
  &__inner {
    @include flex(space-between, center, row);
    padding: 10px 15px;
    &::before {
      @include same(20px);
      content: "";
      display: block;
    }
    .icon {
      @include same(20px);
      cursor: pointer;
    }
    .title {
      @include string(16px, 24px, -0.48px, 500, center, var(--black));
    }
  }
}

.go-back-title-bar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  &.not-bg {
    background-color: var(--white);
  }
  &__inner {
    @include flex(space-between, center, row);
    padding: 10px 17px 10px 15px;
    align-items: center;
    &.not-action {
      padding: 10px 15px;
      &::after {
        @include same(20px);
        content: "";
        display: block;
      }
    }
    .icon {
      @include same(20px);
      cursor: pointer;
    }
    .title {
      @include string(16px, 24px, -0.48px, 500, center, var(--black));
    }
    .action-btn {
      @include string(16px, 24px, -0.96px, 700, rigth, var(--purple06));      
      &:disabled {
        color: var(--purple04);
        cursor: default;
      }
    }
  }
}

.home {  
  background-color: var(--gray100);
  padding: 20px 0 40px;
  height: 100%;
  .notice-card {
    .swiper {
      background-color: var(--gray200);
      border-radius: 8px;
      .swiper-pagination {
        // text-align: right;
        // transform: translateX(12px);
        right: 12px;
        left: auto;
        width: auto;
        .swiper-pagination-bullet {
          @include same(5px);
          margin: 0 2.5px;
          background-color: var(--gray400);
          opacity: 1 !important;
          &.swiper-pagination-bullet-active {
            background-color: var(--purple06);          
          }
        }
      }
      .swiper-wrapper {
        .swiper-slide {
          .notice-card {
            @include flex(flex-start, center, row);
            gap: 10px;
            padding: 17px 12px;
            &__tag {
              @include flex;
              min-width: 21px;
              height: 18px;
              padding: 0 8px;
              background-color: var(--black);
              border-radius: 20px;
              p {
                @include string(12px, 16px, normal, 600, center, var(--white));
              }
            }
            &__title {
              @include string(14px, 22px, normal, 500, left, var(--black));
              white-space: nowrap;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              &.cursor {
                cursor: pointer;
              }
            }
          }
        }
      }
    }   
  }
  
  .program-wrapper {
    min-height: 213px;
    margin-top: 40px;
    padding-bottom: 80px;
    .program-title {
      margin-bottom: 15px;
      p {      
        @include string(20px, 135%, 0.1px, 600, left, var(--black));
      }
    }
    .program-card {
      .swiper {
        .swiper-wrapper {
          box-sizing: content-box;
          .swiper-slide {
            width: fit-content !important;
            @include flex;
            .MuiSkeleton-root {
              border-radius: 16px;
            }
            .slide-card {
              @include disableDrag;
              @include flex(space-between, flex-start);
              width: 164px;
              min-width: 164px;
              height: 213px;
              min-height: 213px;  
              cursor: pointer;
  
              .card-cover {
                @include same(164px);
                position: relative;
                &__coming {
                  @include flex;
                  @include same(100%);
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 1;
                  background-color: #000000b3;
                  border-radius: 8px;
                  p {
                    @include string(12px, 16px, normal, 600, center, var(--white));
                  }
                }
                &__on {
                  @include flex;
                  position: absolute;
                  top: 10px;
                  left: 10px;
                  z-index: 1;
                  background-color: var(--pink10);
                  border-radius: 4px;                
                  padding: 2px 7px;
                  p {
                    @include string(13px, normal, -0.845px, 700, center, var(--white));
                  }
                }
                &__image {
                  @include same(100%);
                  object-fit: contain;
                  border-radius: 8px;
                }
              }
              .card-info {
                margin-top: 8px;
                padding: 0 4px;
                width: 100%;
                &__title {
                  @include string(16px, 24px, normal, 500, left, var(--gray900));
                  white-space: nowrap;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
                &__time {
                  margin-top: 1px;
                  display: grid;
                  grid-template-columns: 11px auto;
                  gap: 3px;
                  align-items: center;
                  .time-icon {
                    @include same(11px);
                  }
                  p {                  
                    @include string(12px, 16px, normal, 600, left, var(--gray600));
                  }
                }
              }
            }
          }
        }
      } 
    }  
  }
  
  .latest-card {
    min-height: 280px;
    margin-top: 24px;
    .swiper {
      .swiper-wrapper {
        box-sizing: content-box;
        .swiper-slide {
          width: fit-content !important;
          @include flex;
          .MuiSkeleton-root {
            border-radius: 16px;
          }
          .slide-card {
            @include disableDrag;
            @include flex(flex-start, flex-start);
            width: 276px;
            min-width: 276px;
            height: 280px;
            min-height: 280px;
            border-radius: 16px;    
            background-color: var(--white);
            &__inner {
              @include flex(space-between, flex-start);
              padding: 32px 24px;
              height: 100%;
              width: calc(100% - 48px);
              .top-inner {
                @include flex(flex-start, flex-start);
                .card-title {
                  display: grid;
                  grid-template-columns: 40px auto;
                  gap: 8px;
                  align-items: center;
                  &__profile {
                    @include same(40px);
                    background-color: var(--gray500);
                    border-radius: 50%;
                  }
                  &__desc {
                    @include flex(flex-start, center, row);
                    gap: 3px;
                   .id {
                      @include string(16px, 24px, normal, 700, left, var(--purple08));
                    }
                   .info {
                      @include string(16px, 24px, normal, 700, left, var(--gray900));
                    } 
                  }
                }
                .card-content {
                  margin-top: 12px;
                  display: grid;
                  grid-auto-rows: auto;
                  gap: 16px;
                  &__desc {
                    @include string(14px, 22px, normal, 500, left, var(--gray800));
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                  }
                  &__tags {
                    @include flex(flex-start, center, row);
                    flex-wrap: wrap;
                    gap: 8px;
                    li {
                      @include flex;
                      height: 24px;
                      padding: 0 8px;
                      border-radius: 100px;
                      background-color: var(--purple01);
                      p {                  
                        @include string(14px, 22px, normal, 500, left, var(--purple07));
                      }
                    }
                  }
                }
              }
              .card-result-btn {
                @include flex;
                margin-top: 32px;
                @include size(100%, 44px);  
                p {
                  @include string(16px, 24px, normal, 700, center, var(--white));
                }
                background-color: var(--purple09);
                border-radius: 4px;
          
                &:disabled {
                  background-color: var(--gray200);
                  color: var(--gray400);
                  cursor: default;
                }
              }
            }
          }
        }
      }
    }   
  }
}

.reports-loading {
  @include flex;
  background: url($G-path-content + '/reports/bg-2.png') $G-img-cover;   
  position: fixed;
  top: 0;
  transform: translateX(-50%);
  left: 50%;
  max-width: 480px;
  width: 100%;
  height: 100%;
  z-index: 15;
  .img-wrapper {
    @include flex;
    @include same(80px);
    border-radius: 50%;
    background-color: var(--bg02);
    img {
      @include same(66.66px);
      transform: translate(2px, -2px);
    }
  }
  .desc {
    margin-top: 12px;
    p {
      @include string(11px, 16.5px, -0.22px, 700, center, var(--purple08));
    }
  }
}

.reports {
  background: url($G-path-content + '/reports/bg.png') $G-img-cover;   
  height: 100%;
  &__calendar {
    user-select: none;
    background-color: var(--white);

    .date-switcher {
      @include flex;
      height: 44px;
      .date-change-btn {
        @include flex(flex-start, center, row);
        @include string(20px, 28px, -0.08px, 700, center, var(--black));
        &::after {
          @include same(20px);
          background: url($G-path-icon + 'icon-down.svg') $G-img-contain;
          content: '';
          display: block;
        }
        
        .date-str {
          min-width: 90px;
        }
      }
    }
    .calendar-body {
      padding: 8px 20px 0;
      .weekdays {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        align-items: center;
        justify-items: center;
        gap: 4.5px;
        p {
          @include string(14px, normal, normal, 700, center, var(--gray900));
          padding: 11px 0 9px;
          max-width: 44px;
          width: 100%;
        }
      }
      .days-wrapper {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        text-align: center;
        justify-items: center;
        gap: 4px 4.5px;
        .days-inner {
          @include flex(flex-start, center);
          max-height: calc(64px - 20px);
          height: calc(64px - 20px);
          padding: 6px 0 14px;
          max-width: 44px;
          width: 100%;
          cursor: pointer;
          &.existsWeekly {
            position: relative;
            .weekly-created {
              position: absolute;
              top: 0;
              right: 2px;
              z-index: 1;
            }
          }
          &.exists {
            padding-top: 4px;
            max-height: calc(64px - 18px);
            height: calc(64px - 18px);
            &.existsWeekly {
              .weekly-created {
                right: 0;
              }
            }
            @include flex(space-between, center);
            .sticker-container {
              max-height: 28px;
              .daily-available {
                max-width: 24px;
                object-fit: contain;
              }
              .daily-created {
                max-width: 28px;
                object-fit: contain;
              }
            }
            .day {
              @include string(12px, 12px, normal, 600, center, var(--gray500));
            }
            &.today .day {         
              font-size: 12px;
              line-height: 18px;
            }
          }
          .day {
            @include string(14px, 14px, -0.7px, 500, center, var(--gray500));
          }
          &.outside {
            .day {
              color: var(--gray200);
            }
          }
          &.today {
            .day {            
              border-radius: 20px;
              background: var(--orange01, #FFF8E0);
              color: var(--orange04);
              padding: 0 10px;
              line-height: 22px;
            }
          }
          &.future {
            .day {
              color: var(--gray500);
            }
          }
        }
      }
    }
    .info {
      @include flex(flex-end, center, row);
      padding: 51px 20px 20px;
      position: relative;
      &::before {
        @include size(247px, 48px);
        content: '';
        display: block;
        border-radius: 100px;
        background: rgba(117, 102, 206, 0.25);
        filter: blur(7.5px);
        position: absolute;
        bottom: -35px;
        left: 50%;
        transform: translateX(-50%);
      }
      .week-reports-request {
        @include size(247px, 48px);
        @include flex(center, center, row);
        gap: 8px;
        position: absolute;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 100px;
        background: var(--purple09, #360E67);
        z-index: 1;
        &::before {
          @include same(24px);
          background: url($G-path-icon + 'icon-search.svg') $G-img-contain;
          content: '';
          display: block;
        }
        p {
          @include string(16px, 24px, -0.96px, 700, center, var(--white));
        }
      }
      .onboarding-btn {
        &::after {
          @include same(24px);
          background: url($G-path-icon + 'icon-info.svg') $G-img-contain;
          content: '';
          display: block;
        }
      }
    }
  }
}

.memorybox {
  position: relative;
  &__head {
    @include flex;
    position: sticky;
    top: 0;
    left: 0;
    background-color: var(--white);
    z-index: 1;
    .date-switcher {
      @include flex;
      height: 44px;
      .date-change-btn {
        @include flex(flex-start, center, row);
        @include string(20px, 28px, -0.08px, 700, center, var(--black));
        &::after {
          @include same(20px);
          background: url($G-path-icon + 'icon-down.svg') $G-img-contain;
          content: '';
          display: block;
        }
        
        .date-str {
          min-width: 51px;
        }
      }
    }
    .tab-switcher {
      @include flex(center, center, row);
      gap: 20px;
      margin: 12px 0;
      padding: 4px;
      border-radius: 30px;
      background: var(--purple09);
      .tab-switcher-item {
        @include flex;
        cursor: pointer; 
        padding: 0 12px;
        .icon-set {         
        }
        &.isActive {     
          display: grid;
          grid-template-columns: 24px auto;
          align-items: center;     
          gap: 6px;
          border-radius: 20px;
          background: var(--white);
          padding: 2px 12px;
          p {
            @include string(12px, 18px, normal, 600, center, var(--purple09));
          }
          .icon-set {          
            padding: 0;
            background-color: var(--purple09) !important;
          }
        }
        &.all {
          .icon-set {           
            @include setIcon(24px, 24px, 'icon-tab-all', var(--purple04));
          }
        }
        &.letter {
          .icon-set {           
            @include setIcon(24px, 24px, 'icon-tab-letter', var(--purple04));
          }
        }
        &.diary {
          .icon-set {           
            @include setIcon(24px, 24px, 'icon-tab-diary', var(--purple04));
          }
        }
      }
    }
  }
  &__body {
    margin-top: 24px;
    margin-bottom: 50px;
    &.minHeight {
      margin: 0;
      height: calc(100vh - 168px);
    }
    .not-list {
      @include flex;
      height: 100%;
      .rabbit {
        height: 100%;
        max-height: 163px;
        aspect-ratio: 119/163;
      }
      &__desc {
        margin-top: 28px;
        p {
          @include string(14px, 22px, -0.7px, 500, center, var(--gray400));
        }
      }
    }
    .box-group {
      padding-left: 23px;
      padding-right: 17px;
      .loading-skeleton {
        @include flex(center, flex-start);
        min-height: 61px;
      }
      &.line {
        margin-top: 52px;
      }
      &__month {
        @include string(20px, 28px, -0.08px, 700, left, var(--black));
        margin-bottom: 12px;
      }
      &__items {
        .box-item {
          box-shadow: 4px 2px 9px 0px rgba(0, 0, 0, 0.04);
          cursor: pointer;
          position: relative;
          padding: 10px 26px 16px 55px;
          border-radius: 4px;
          border: 1px solid var(--gray100, #F5F5FA);
          background: #FFF;
          &:not(:first-of-type) {
            margin-top: 6px;
          }
          &__img {
            position: absolute;
            &.letter {
              top: -4px;
              left: -5px;
            }
            &.diary {
              top: 0;
              left: 12px;
            }
          }
          &__desc {
            &--date {
              @include string(14px, 16px, normal, 700, left, var(--gray800));
            }
            &--content {
              @include string(12px, 18px, normal, 600, left, var(--gray500));
              white-space: nowrap;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

.letterbox {
  position: relative;
  background-color: var(--white);
  &.isHeight {
    height: calc(100% - 92px);
  }
    
  &__not {
    @include flex;
    height: calc(100% - 92px);
    .rabbit {
      height: 280px;
      aspect-ratio: 300/280;
    }
    p {
      @include string(16px, 24px, normal, 600, center, var(--gray800));
      margin-top: 16px;
    }
  }

  &__inner {
    @include breakpoint(tablet) {
      padding-left: 44px;
      padding-right: 44px;
    }
    padding-top: 28px;
    padding-bottom: 80px;
    .letter-list {
      display: grid;
      grid-auto-rows: auto;
      gap: 25px;

      .loading-skeleton {
        display: grid;
        grid-template-columns: 128px auto;
        gap: 8px;
        align-items: center;
      }

      .letter-item {
        cursor: pointer;
        &__inner {
          display: grid;
          grid-template-columns: 128px auto;
          gap: 8px;
          align-items: flex-end;
          .letter-img {
            width: 100%;
            height: 100%;
            aspect-ratio: 1/1;
            object-fit: contain;
          }
          .letter-info {
            @include flex(flex-start, flex-start);
            .stamp-img {
              @include size(37.48px, 28px);
            }
            &__content {
              margin: 8px 0 12px;
              .date {
                @include string(12px, 16px, normal, 600, left, var(--black));
              }
              .desc {
                @include string(12px, 16px, normal, 600, left, var(--gray600));
              }
            }
          }
        }
      }
    }
  }
}
.reports-weekly-result {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: 44px 1fr;
  .not-list {
    @include flex;
    height: 100%;
    background-color: var(--white);
    .rabbit {
      height: 100%;
      max-height: 163px;
      aspect-ratio: 119/163;
    }
    &__desc {
      margin-top: 28px;
      p {
        @include string(14px, 22px, -0.7px, 500, center, var(--gray400));
      }
    }
  }
  .reports-weekly-result-container {
    padding-bottom: 30px;
    background: url($G-path-content + '/reports/bg-4.png') repeat top / contain;  
    .reports-weekly-result-inner {    
      padding: 24px 20px;
      margin: 0 20px;
      border-radius: 8px;
      background: var(--bg01);
      height: calc(100% - 48px);
      .result-core-title {
        @include flex;
        padding: 43px 0;
        .week-name {
          @include string(20px, 28px, -0.08px, 700, center, var(--gray800)); 
          margin-bottom: 4px;
        }
        .start-end-date {
          @include string(14px, 22px, -0.7px, 500, center, var(--gray700)); 
        }
      }
      .emotion {
        @include flex(center, center, row);
        gap: 6px;
        flex-wrap: wrap;
        padding: 20px 0;
        border-bottom: 1px solid var(--bg03, #DED3FF);
        margin: 0 6px;
        .emotion-img {
          @include same(33px);
          object-fit: contain;
        }
      }
      .result-desc {
        margin-top: 48px;
        &__days {
          @include string(16px, 24px, -0.96px, 700, center, var(--gray800)); 
          margin-bottom: 16px;
        }
        &__topic {
          @include string(16px, 32px, -0.96px, 500, left, var(--gray700)); 
        }
      }
      .written-wrapper {
        margin-top: 36px;
        padding: 10px 15px;
        display: grid;
        grid-template-columns: repeat(3, auto);
        justify-content: space-between;
        align-items: center;
        li {
          display: grid;
          grid-template-rows: 48px auto;
          justify-items: center;
          .desc {
            @include flex;
            p {
              @include string(11px, 16.5px, -0.22px, 400, center, var(--gray700)); 
            }
            span {
              @include string(14px, normal, normal, 700, center, var(--gray800)); 
            }
          }
        }
      }
    }
  }
}

.reports-daily-result {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: 44px 1fr;
  .not-list {
    @include flex;
    height: 100%;
    background-color: var(--white);
    .rabbit {
      height: 100%;
      max-height: 163px;
      aspect-ratio: 119/163;
    }
    &__desc {
      margin-top: 28px;
      p {
        @include string(14px, 22px, -0.7px, 500, center, var(--gray400));
      }
    }
  }
  .reports-daily-result-container {
    padding-bottom: 30px;
    background: url($G-path-content + '/reports/bg-4.png') repeat top / contain;  
    .reports-daily-result-inner {    
      padding: 28px 20px;
      margin: 0 20px;
      border-radius: 8px;
      background: #FFFCF3;
      height: calc(100% - 56px);
      .result-core-title {
        display: grid;
        grid-template-rows: 140px auto;
        gap: 16px;
        justify-items: center;
        .core-emotion-img {
          @include same(140px);
          object-fit: contain;
        }
        .date {
          @include string(16px, 24px, -0.96px, 700, center, var(--gray800)); 
        }
      }
      .letter-analyses {
        margin-top: 32px;
        &__item {
          &:not(:last-of-type) {
            border-bottom: 1px solid var(--orange02);
            box-sizing: border-box;
          }
          display: grid;
          grid-template-columns: 33px auto;
          gap: 12px;
          align-items: center;
          padding: 16px 0;
          .emotion-img {
            @include same(33px);
            object-fit: contain;
          }
          .letter-desc {
            @include flex(flex-start, flex-start);
            overflow: hidden;
            .index {
              @include string(14px, 17px, normal, 700, left, var(--gray800)); 
            }
            .topic {
              @include string(12px, 18px, normal, 600, left, var(--gray700)); 
              white-space: nowrap;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 90%;
            }
          }
        }
      }
      .result-desc {
        margin-top: 60px;
        &__days {
          @include string(16px, 24px, -0.96px, 700, center, var(--gray800)); 
          margin-bottom: 16px;
        }
        &__topic {
          @include string(16px, 32px, -0.96px, 500, left, var(--gray700)); 
        }
      }
    }
  }
}

.reports-week {
  height: 100%;
  position: relative;
  &__wrapper { 
    display: grid;
    grid-template-rows: 44px auto 56px;
    height: 100%;
    &--foot {
      @include flex(flex-start);
      padding: 0 20px;
      position: sticky;
      bottom: 12px;
      left: 0;
      z-index: 2;
      background-color: var(--white);
      .request-btn {
        @include string(16px, 24px, -0.96px, 700, center, var(--white)); 
        width: 100%;
        height: 56px;
        border-radius: 4px;
        background: var(--purple09);
      
        &:disabled {
          background-color: var(--gray200);
          color: var(--gray400);
          cursor: default;
        }
      }
    }
    &--inner {
      display: grid;
      grid-template-rows: 28px 1fr;
      padding-top: 24px;
      padding-bottom: 50px;

      .date-title {
        @include string(20px, 28px, -0.08px, 700, left, var(--gray700)); 
      }
      .not-list {
        @include flex;
        height: 100%;
        .rabbit {
          height: 100%;
          max-height: 163px;
          aspect-ratio: 119/163;
        }
        &__desc {
          margin-top: 28px;
          p {
            @include string(14px, 22px, -0.7px, 500, center, var(--gray400));
          }
        }
      }
      .list-container {
        display: grid;
        grid-auto-rows: 102px;
        gap: 6px;
        margin-top: 16px;
        .list-item {
          border-radius: 8px;
          border: 1px solid var(--gray100);
          box-sizing: border-box;
          max-height: 102px;
          height: 102px;
          box-sizing: border-box;
          background: var(--white);
          box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.03);
          position: relative; // 배경 이미지 위치 조정용
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          &.isSelected::after { 
            display: block;       
          }
          .isSeiected-item {
            @include same(56px);
            position: absolute;
            top: 29px;
            right: 0;
          }
          &::after {
            @include same(100%);
            border-radius: 8px;
            content: "";
            display: none;
            position: absolute;
            top: 0;
            left: 0;    
            border: 2px solid var(--purple04);
          }
          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 10px;
            right: 24px;
            width: 100px;
            height: 81px;
          }
          &:nth-child(4n + 1)::before {
            background: url($G-path-content + '/reports/stamp-1.svg') $G-img-contain; 
          }
          &:nth-child(4n + 2)::before {
            background: url($G-path-content + '/reports/stamp-2.svg') $G-img-contain; 
          }
          &:nth-child(4n + 3)::before {
            background: url($G-path-content + '/reports/stamp-3.svg') $G-img-contain; 
          }
          &:nth-child(4n)::before {
            background: url($G-path-content + '/reports/stamp-4.svg') $G-img-contain; 
          }
          .desc {
            @include flex(space-between, flex-start);
            position: relative;
            z-index: 1;
            height: calc(100% - 44px);
            padding: 23px 20px 21px;
            .desc-weekName {
              @include string(16px, 24px, -0.96px, 700, left, var(--gray800)); 
            }
            .desc-date {
              @include string(12px, 18px, normal, 600, left, var(--gray500)); 
            }
          }
        }
      }
    }
  }
}

.write {
  height: 100%;
  position: relative;

  &__wrapper {
    background: url($G-path-content + '/write/bg.png') $G-img-cover;   
    display: grid;
    grid-template-rows: 44px auto;
    height: 100%;
    &--inner {
      display: grid;
      grid-template-rows: 1fr 52px 46px;
      position: relative;
    }
    .write-area-wrapper {
      margin: 88px 0 68px;
      position: relative;
      
      .asset-container {
        .asset-img {
          aspect-ratio: 74/75;
          height: 75px;
          position: absolute;
          left: 42px;
          top: -67.5px;
          z-index: 1;
        }
        .speech-bubble {
          aspect-ratio: 157/37.12;
          height: 37.12px;
          position: absolute;
          left: 113px;
          top: -67.5px;
          z-index: 1;
        }
        .letter-accessory {
          aspect-ratio: 50/60;
          height: 60px;
          position: absolute;
          right: 12px;
          top: 20px;
          transform: translateX(-50%);
          z-index: 1;
        }
        .diary-accessory {
          aspect-ratio: 29/71.11;
          height: 71.11px;
          position: absolute;
          right: 35px;
          top: 0;
          transform: translateX(-50%);
          z-index: 1;
        }
      }
      .write-area-inner {
        display: grid;
        grid-template-rows: 96px 1fr;
        height: calc(100% - 36px);
        padding-bottom: 36px;
        border-radius: 8px;
        border: 1px solid var(--gray100);
        background: var(--white);
        box-shadow: 9px 8px 7px 0px rgba(148, 82, 214, 0.04), 4px 3px 5px 0px rgba(148, 82, 214, 0.04);
        position: relative;
        .writing-letter-post {
          position: absolute;
          bottom: 20px;
          right: 16px;
        }
        .write-info {
          @include flex(flex-start, flex-start);
          padding: 26px 18px 12px;
          .title {
            @include string(20px, 28px, -0.08px, 700, left); 
            margin-bottom: 6px;
            &.letter {
              color: var(--purple05);
            }
            &.diary {
              color: var(--orange05);
            }
          }
          .date {
            @include string(12px, 24px, normal, 500, left, var(--gray500)); 
          }
        }
        .write-textarea {
          @include scrollCustomSmall(var(--gray300));
          @include string(16px, 32px, -0.96px, 500, left, var(--gray700));
          padding: 0 18px 0;
          resize: none;
          position: relative;
          z-index: 1;
          background-color: transparent;
          &::placeholder {
            @include string(16px, 32px, -0.96px, 500, left, var(--gray300));                
          }
        }

      }
    }
    .write-status {
      @include flex(space-between, center, row);
      margin: 0 0 12px;
      .letter-usage {
        @include flex(flex-start, flex-end, row);
        .length {
          @include string(40px, 40px, normal, 600, left, var(--gray200));
        }
        .limit {
          @include string(9px, 9px, normal, 600, left, var(--gray600));
          transform: translateY(-6px);
        }
      }
      .reflash-timer {
        &__desc {
          @include string(9px, 16px, normal, 600, left, var(--gray600));
        }
        &__inner {
          display: grid;
          grid-template-columns: 15px auto;
          gap: 6px;
          align-items: center;
          .timer-num {
            @include string(16px, 24px, -0.96px, 700, left, var(--gray300));
          }
        }
      }
    }
    .write-mode-control {
      @include flex(space-between, center, row);
      padding: 11px 8px;
      border-top: 1px solid var(--gray200);
      background-color: var(--gray100);
      position: sticky;
      bottom: 0;
      left: 0;
      z-index: 1;
      .change-btn-wrapper {
        @include flex(flex-start, center, row);
        gap: 12px;
        button {
          display: grid;
          grid-template-columns: 24px auto;
          gap: 5px;
          align-items: center;
          img {
            @include same(24px);
          }
          p {            
            @include string(11px, normal, -0.22px, 400, left, var(--gray600));
          }
        }
      }
      .string-length {
        @include flex(flex-end, center, row);
        gap: 4px;
        p {          
          @include string(12px, normal, normal, 600, left);
        }
        .length {
          color: var(--gray800)
        }
        .limit {
          color: var(--gray500)
        }
      }
    }
    .snackbar {
      @include flex;
      position: absolute;
      bottom: 58px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      border-radius: 30px;
      background: var(--gray800);
      height: 26px;
      padding: 0 20px;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      p {
        @include string(14px, 22px, -0.7px, 500, center, var(--white));
        white-space: nowrap;
      }

      &.fade-in {
        opacity: 1;
      }

      &.fade-out {
        opacity: 0;
      }
    }
  }
}

.login {
  background-color: var(--white);
  height: 100%;
  &__inner {
    display: grid;
    grid-template-rows: 1fr 100px;
    gap: 24px;
    padding: 24px 16px 40px;
    height: calc(100% - 64px);
    .body {
      @include flex;
      height: 100%; 
      .rabbit {
        height: 288px;
        aspect-ratio: 1/1;
      }
      .logo {
        height: 44px;
        aspect-ratio: 204/44;
        margin-top: 12px;
      }
    }
    .foot {
      display: grid;
      grid-auto-rows: repeat(2, auto);
      gap: 16px;
      align-items: center;
      .link {
        @include string(12px, 16px, normal, 600, center);
        text-decoration-line: underline;
        color: var(--gray600) !important;
      }
      .login-btn {
        @include string(16px, 24px, normal, 700, center, var(--black));
        display: grid;
        grid-template-columns: 20px auto;
        gap: 8px;
        align-items: center;
        justify-content: center;
        background-color: #FEE500;
        border-radius: 8px;
        height: 56px;

        &.not {
          &::before {
            @include same(20px);
            content: "";
            display: block;
            background: url($G-path-icon + 'talk.svg') $G-img-contain;
          }
        }
      }
    }
  }
}

.error {
  background-color: var(--white);
  height: 100%;
  &__inner {
    padding: 20px;
    display: grid;
    grid-template-rows: 40px 1fr 56px;
    height: calc(100% - 40px);

    .head {
      @include flex(flex-start, center, row);
      height: 40px;
      .logo {
        height: 24px;
        object-fit: contain;
      }
    }
    .body {
      display: grid;
      grid-template-rows: 255px auto;
      justify-items: center;
      height: calc(100% - 83px); 
      margin-top: 83px;
      .img-wrapper {
        @include flex;
        height: 255px;
        .rabbit {
          height: 255px;
          aspect-ratio: 1/1;
        }
        .img4xx {
          height: 255px;
          aspect-ratio: 288/255;
        }
        .img5xx {
          height: 184.76px;
          aspect-ratio: 258.96/184.76;
        }
      }
      &__desc {
        @include flex;
        .code {
          @include string(20px, 28px, normal, 700, center, var(--black));
          margin-bottom: 20px;
        }
        .message {
          @include string(14px, 22px, normal, 500, center, var(--gray700));
          min-height: 44px;
        }
      }
    }
    .foot {
      display: grid;
      grid-auto-rows: auto;
      button {
        @include flex;
        @include string(16px, 24px, normal, 700, center, var(--white));
        background-color: var(--purple09);
        border-radius: 4px;
        min-height: 56px;
  
        &:disabled {
          background-color: var(--gray200);
          color: var(--gray400);
          cursor: default;
        }
      }
    }
  }
}

.result {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 120px;
  // background: linear-gradient(174deg, #210245 23.31%, #7331B7 60.29%, #F0C9F3 86.26%);
  background: linear-gradient(174deg, #210245 23.31%, #7331B7 65.29%, #F0C9F3 86.26%);
  position: relative;

  &::after {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    display: block;
    @include same(100%);
    background: url($G-path-content + 'bg-start-item.png') $G-img-contain;
    opacity: 0.8;
  }


  &.layout {
    .result__inner .body {
      .script {
        @include flex(center, flex-start);
        padding-left: 20px;
        padding-bottom: 20px;
        &__inner {
          p {
            @include string(20px, 28px, -0.08px, 700, left, var(--white));
            &:not(:last-of-type) {
              margin-bottom: 12px;
            }
            opacity: 0;          
          }
    
          .desc1 {
            animation: fadein 1.2s;
            animation-fill-mode: forwards;
            animation-delay: 1.2s;
          }
          .desc2 {
            animation: fadein 1.2s;
            animation-fill-mode: forwards;
            animation-delay: 3.2s;
          }
          .desc3 {
            animation: fadein 1.2s;
            animation-fill-mode: forwards;
            animation-delay: 5.2s; 
          }
        }
      }
      .assets-container {
        .box {
          .rabbit {
            @include size(208px, 203px);
          }
        }
      }
    }
  }


  &__inner {
    padding: 20px 20px 0;
    display: grid;
    grid-template-rows: 40px 1fr;
    gap: 12px;
    position: relative;
    z-index: 1;

    .head {
      @include flex(flex-start, center, row);
      height: 40px;
      .logo {
        height: 24px;
        object-fit: contain;
      }
    }
    .body {
      display: grid;
      grid-template-rows: 1fr auto;
      height: 100%;
      .bubble-container {        
        @include string(12px, 16px, normal, 600, left, var(--white));
        border-radius: 8px;
        backdrop-filter: blur(2px);
        position: relative;
        &::after {
          @include same(20px);
          content: "";
          position: absolute;
          bottom: -19px; /* 꼬리의 아래쪽 위치 조정 */
          left: 50%;    /* 꼬리의 왼쪽 위치 조정 */
          transform: translate(-50%);
          transition: background 0.25s ease;
        }
        &__inner {
          @include scrollCustomSmall;
          // height: 378px;
          overflow-y: auto;
          max-height: calc(var(--pvh) - 446px);
          padding: 34px 34px 0;
          p {
            @include string(16px, 24px, normal, 500, left, var(--white));
          }
        }
        transition: background-color 0.25s ease;
        &.type-T {
          background-color: rgba(102, 163, 255, 0.80);
          &::after {
            background: url($G-path-content + 'speech-bubble-tail-b.svg') $G-img-contain;
          }
        }
        &.type-F {
          background-color: rgba(255, 145, 153, 0.90);
          &::after {
            background: url($G-path-content + 'speech-bubble-tail-p.svg') $G-img-contain;
          }
        }
      }
      .assets-container {
        margin-top: 10px;
        display: grid;
        grid-template-rows: auto 37px;
        gap: 120px;
        .box {
          position: relative;
          height: 37px;
          &::after {
            background-color: var(--purple10);
            display: block;
            content: '';
            position: absolute;
            left: -2000px;
            right: -2000px;
            top: 0;
            bottom: 0;
            z-index: -1;
          }

          .desc {
            position: absolute;
            bottom: 136px;
            right: 183px;
            font-family: 'Ownglyph_kimkonghae';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            text-align: right;
            color: var(--white);
          }
          
          .mic {
            position: absolute;
            bottom: 35px;
            right: 210px;
          }
          .rabbit {
            position: absolute;
            bottom: 8px;
            right: 0;
            transform: translateX(10px);
          }
        }
      }
    }
  }
  &__footer {
    width: 100%;
    background-color: var(--gray100);
    position: relative;
    z-index: 1;
    .footer-inner {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
      align-items: center;
      padding: 38px 20px;
      margin: auto;
      height: 44px;
      .result-btn {
        @include string(16px, 24px, normal, 700, center, var(--gray900));
        display: grid;
        grid-template-columns: 24px auto;
        gap: 8px;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--gray200);
        background-color: var(--white);
        border-radius: 8px;
        padding: 10px;
        width: 100%;

        @each $icon in again, home {
          &.#{$icon} {
            &::before {
              @include same(100%);
              background: url($G-path-icon + '#{$icon}.svg') $G-img-contain;
              content: '';
              display: block;
            
            }
          } 
        }
      }
    }
  }
}

.register {
  height: 100%;
  display: grid;
  grid-auto-rows: auto;
  &__inner {
    background-color: var(--gray100);
    padding: 20px;
    display: grid;
    grid-template-rows: 40px 1fr 56px;
    gap: 24px;
    align-items: space-between;
    .head {
      @include flex(flex-start, center, row);
      height: 40px;
      .logo {
        height: 24px;
        object-fit: contain;
      }
    }
    .body {
      .letter-content {
        padding: 8px;
        background-color: var(--white);
        border-radius: 8px;
        .letter-content-border {
          @include flex;
          border-radius: 8px;
          border: 2px solid var(--purple03);
          padding: 24px 12px;
          .title {
            @include string(16px, 24px, normal, 700, center, var(--black));
          }
          .content {
            @include flex;
            .desc {
              @include flex;
              margin: 19px 0;
              li {
                font-family: 'Ownglyph_kimkonghae';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                text-align: center;
                color: var(--gray900);
              }
            }
            .info {
              font-family: 'Ownglyph_kimkonghae';
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              text-align: center;
              color: var(--gray500);
            }
          }
          .img-layout {
            @include flex(space-between, flex-end, row);
            width: 100%;
            margin-top: 10px;
            .post {
              font-family: 'Ownglyph_kimkonghae';
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              color: var(--purple06);
            }
            .stamp {
              @include size(53.55px, 40px);
            }
          }
        }
      }

      .user-data-container {
        display: grid;
        grid-auto-rows: auto;
        gap: 24px;
        margin-top: 36px;
        width: 100%;
        .data-box {
          width: 100%;
          &.rows {
            @include flex(space-between, center, row);
          }
          &__title {
            margin-left: 8px;
            p {
              @include string(12px, 16px, normal, 600, left, var(--gray700));    
              display: inline-block;          
            }
            span {
              @include string(12px, 16px, normal, 600, left, var(--gray500));    
              margin-left: 2px;
            }
          }
          .user-nickname {
            @include string(16px, 24px, normal, 400, left, var(--black));              
            width: 100%;
            padding: 16px;
            border: 1px solid var(--gray200);
            box-sizing: border-box;
            margin-top: 4px;
            border-radius: 4px;
            background: var(--white);
            &::placeholder {
              @include string(16px, 24px, normal, 400, left, var(--gray500));                
            }
          }
          .type-btn-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
            align-items: center;
            padding: 8px;
            height: 84px;
            .type-btn {
              @include string(50px, normal, normal, 500, center, var(--gray300));   
              height: 100%;
              border-radius: 8px;
              background-color: var(--white);
              &.F {
                color: var(--white);
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.15), 0px 3px 1px 0px rgba(0, 0, 0, 0.06);
                background: var(--pink06);
              }
              &.T {
                color: var(--white);
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.15), 0px 3px 1px 0px rgba(0, 0, 0, 0.06);
                background: var(--blue04);
              }
            }
          }
        }
      }
    }
    .foot {
      display: grid;
      grid-auto-rows: auto;
      button {
        @include flex;
        @include string(16px, 24px, normal, 700, center, var(--white));
        background-color: var(--purple09);
        border-radius: 4px;
        min-height: 56px;
  
        &:disabled {
          background-color: var(--gray200);
          color: var(--gray400);
          cursor: default;
        }
      }
    }
  }
}


.myPage {
  &__inner {
    .user-zone {      
      display: grid;
      grid-template-rows: auto 44px;
      gap: 28px;
      background-color: var(--gray100);
      padding: 50px 20px 16px;
      &__data {
        display: grid;
        grid-template-columns: 90px auto;
        gap: 16px;
        align-items: center;
        padding-right: 47px;
        .profile {
          @include same(90px);
          border-radius: 50%;
          overflow: hidden;
        }
        .nickname {
          padding: 0 8px 4px;
          border-bottom: 1px solid var(--black);
          p {
            @include string(16px, 24px, normal, 700, left, var(--black));
          }
        }
      }
      .correction-btn {
        @include string(16px, 24px, normal, 700, center, var(--black));
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        align-items: center;
        justify-content: center;
        background-color: var(--white);
        border-radius: 8px;
        border: 1px solid var(--gray200);

        &::before {
          @include same(20px);
          transform: translateY(-1px);
          content: "";
          display: block;
          background: url($G-path-icon + 'icon-write-b.svg') $G-img-contain;
        }
      }
    }
    .common-zone {
      padding-top: 20px;
      padding-bottom: 20px;
      &__title {
        @include string(20px, 28px, -0.08px, 700, left, var(--black));
        margin-bottom: 20px;
      }
      &__list {
        li {
          padding: 24px 0;
          display: grid;
          grid-template-columns: auto 24px;
          gap: 8px;
          align-items: center;
          cursor: pointer;
          &:not(:last-of-type) {
            border-bottom: 1px solid var(--gray200);
          }
          .icon {
            @include same(24px);
          }

          .title {
            display: grid;
            grid-template-columns: 24px auto;
            gap: 8px;
            align-items: center;
            p {              
              @include string(16px, 24px, normal, 500, left, var(--black));
            }
          }
        }
      }
    }
  }
}

.editProfile {
  height: 100%;
  display: grid;
  grid-template-rows: 44px 1fr;
  &__inner {
    background-color: var(--gray100);
    padding: 26px 20px 20px;
    display: grid;
    grid-template-rows: 1fr 56px;
    gap: 26px;
    align-items: space-between;
    .body {
      .user-data-container {
        display: grid;
        grid-auto-rows: auto;
        gap: 24px;
        width: 100%;
        .data-box {
          width: 100%;
          &.rows {
            @include flex(space-between, center, row);
          }
          &__title {
            margin-left: 8px;
            p {
              @include string(12px, 16px, normal, 600, left, var(--gray700));    
              display: inline-block;          
            }
            span {
              @include string(12px, 16px, normal, 600, left, var(--gray500));    
              margin-left: 2px;
            }
          }
          .user-nickname {
            @include string(16px, 24px, normal, 400, left, var(--black));              
            width: 100%;
            padding: 16px;
            border: 1px solid var(--gray200);
            box-sizing: border-box;
            margin-top: 4px;
            border-radius: 4px;
            background: var(--white);
            &::placeholder {
              @include string(16px, 24px, normal, 400, left, var(--gray500));                
            }
          }
          .type-btn-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
            align-items: center;
            padding: 8px;
            height: 84px;
            .type-btn {
              @include string(50px, normal, normal, 500, center, var(--gray300));   
              height: 100%;
              border-radius: 8px;
              background-color: var(--white);
              &.F {
                color: var(--white);
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.15), 0px 3px 1px 0px rgba(0, 0, 0, 0.06);
                background: var(--pink06);
              }
              &.T {
                color: var(--white);
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.15), 0px 3px 1px 0px rgba(0, 0, 0, 0.06);
                background: var(--blue04);
              }
            }
          }
        }
      }
    }
    .foot {
      display: grid;
      grid-auto-rows: auto;
      button {
        @include flex;
        @include string(16px, 24px, normal, 700, center, var(--white));
        background-color: var(--purple09);
        border-radius: 4px;
        min-height: 56px;
  
        &:disabled {
          background-color: var(--gray200);
          color: var(--gray400);
          cursor: default;
        }
      }
    }
  }
}

.deleteComplete {
  background-color: var(--white);
  height: 100%;
  &__inner {
    padding: 20px;
    display: grid;
    grid-template-rows: 1fr 56px;
    height: calc(100% - 40px);
    .body {
      display: grid;
      grid-template-rows: auto 89px;
      justify-items: center;
      height: calc(100% - 50px); 
      margin-bottom: 50px;
      &__desc {
        @include flex;
        p {
          @include string(20px, 28px, -0.08px, 700, center, var(--gray800));
          margin-bottom: 12px;
        }
        span {
          @include string(16px, 24px, normal, 500, center, var(--gray800));
        }
      }
      .logo {
        @include size(173px, 80px);
      }
    }
    .foot {
      display: grid;
      grid-auto-rows: auto;
      button {
        @include flex;
        @include string(16px, 24px, normal, 700, center, var(--white));
        background-color: var(--purple09);
        border-radius: 4px;
        min-height: 56px;
  
        &:disabled {
          background-color: var(--gray200);
          color: var(--gray400);
          cursor: default;
        }
      }
    }
  }
}

.deleteAccount {
  height: 100%;
  display: grid;
  grid-template-rows: 44px 1fr;
  &__inner {
    padding: 26px 0 0;
    display: grid;
    grid-template-rows: 1fr 124px;
    gap: 16px;
    align-items: space-between;
    position: relative;
    .body {
      padding: 0 20px 20px;
      .delete-data-container {
        display: grid;
        grid-auto-rows: auto;
        gap: 30px;
        .info-wrapper {
          display: grid;
          grid-auto-rows: auto;
          gap: 30px;
          &__title {
            @include string(20px, 28px, -0.08px, 700, left, var(--black));   
          }
          ul {
            li {
              margin-left: 8px;
              &:not(:last-of-type) {
                margin-bottom: 8px;
              }
              p {
                @include string(14px, 22px, normal, 500, left, var(--gray700));   
                text-indent: -8px;
                margin-left: 8px;
                &::before {
                  content: "•";
                  display: inline-block;
                  margin-right: 7px;
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }
      .delete-data-form {
        display: grid;
        grid-auto-rows: auto;
        gap: 10px;
        &__title {
          @include string(12px, 16px, normal, 600, left, var(--gray500));   
          span {
            margin-right: 5px;
          }
        }
        &__inner {
          li {
            min-height: 48px;
            .row {
              @include flex(flex-start, center, row);
              p {
                @include string(14px, 22px, normal, 500, left, var(--black));   
              }
            }
            .reason-textarea {
              @include scrollCustomSmall(var(--purple06));
              @include string(14px, 22px, normal, 500, left, var(--black));
              padding: 10px 12px;
              min-height: calc(130px - 20px);
              max-height: calc(130px - 20px);
              resize: none;
              border-radius: 8px;
              background: var(--gray200);
              width: calc(100% - 24px);
              &::placeholder {
                @include string(14px, 22px, normal, 500, left, var(--gray500));   
                white-space: pre-line;             
              }
            }
          }
        }
      }
    }
    .foot {
      position: sticky;
      bottom: 0;
      left: 0;
      display: grid;
      grid-auto-rows: 48px 56px;
      padding: 0 20px;
      background-color: var(--gray100);
      button {
        @include flex;
        @include string(16px, 24px, normal, 700, center, var(--white));
        background-color: var(--purple09);
        border-radius: 4px;
        min-height: 56px;
  
        &:disabled {
          background-color: var(--gray200);
          color: var(--gray400);
          cursor: default;
        }
      }
    }
  }
}

.signupTerms {
  height: 100%;
  display: grid;
  grid-template-rows: 44px 1fr;
  &__inner {
    padding: 26px 0 0;
    display: grid;
    grid-template-rows: 1fr 324px;
    gap: 16px;
    align-items: space-between;
    .body {
      padding: 0 20px 20px;
      .data-container {
        display: grid;
        grid-auto-rows: auto;
        gap: 30px;
        .info-wrapper {
          display: grid;
          grid-auto-rows: auto;
          gap: 30px;
          &__title {
            @include string(20px, 28px, -0.08px, 700, left, var(--black));   
          }
          ul {
            li {
              &:not(:last-of-type) {
                margin-bottom: 8px;
              }
              
              p {
                @include string(14px, 22px, normal, 500, left, var(--gray700));   
              }
            }
          }
        }
      }
    }
    .foot {
      display: grid;
      grid-auto-rows: 228px 56px;
      gap: 20px;
      padding: 0 20px 20px;
      &__checkbox {
        .all {
          display: grid;
          grid-template-columns: 48px auto;
          align-items: center;
          border-bottom: 1px solid var(--gray200);
          padding: 10px 0;
          p {
            @include string(20px, 28px, -0.08px, 700, left, var(--black));
          }
          .MuiButtonBase-root.MuiCheckbox-root {
            &.Mui-checked {
              color: var(--purple07);
            }
            color: var(--gray600);
            padding: 0;
            border-radius: 0;
            .MuiSvgIcon-root {
              width: 24px;
              height: 24px;
            }
          }
        }
        ul > li {
          min-height: 48px;
          display: grid;
          grid-template-columns: 48px auto;
          align-items: center;
          .MuiButtonBase-root.MuiCheckbox-root {
            &.Mui-checked {
              color: var(--purple07);
            }
            color: var(--gray600);
            padding: 0;
            border-radius: 0;
            .MuiSvgIcon-root {
              width: 24px;
              height: 24px;
            }
          }
          .rows {
            @include flex(space-between, center, row);
            p {
              @include string(14px, 22px, normal, 500, left, var(--gray600));
            }
            .icon {
              @include same(24px);
            }
          }
        }
      }
      button {
        @include flex;
        @include string(16px, 24px, normal, 700, center, var(--white));
        background-color: var(--purple09);
        border-radius: 4px;
        min-height: 56px;
  
        &:disabled {
          background-color: var(--gray200);
          color: var(--gray400);
          cursor: default;
        }
      }
    }
  }
}